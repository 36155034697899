import task from "@commons/task";
import {sa2Toast, sa2Confirm, sa2Alert} from "@commons/sa2Custom";
import {__t} from "@commons/i18nRef";
import Utils from "./Utils"

export default function showAddInfoForm(form, onComplete) {

    var additionalInfo = {
        freeTextJustification: $('input[name="additionalInfo[freeTextJustification]"]', form).val(),
        picklistTextJustification: Utils.escapeHtml($('input[name="additionalInfo[picklistTextJustification]"]', form).val()),
        bookingId: $('input[name="bookingId"]', form).val(),
    };

    //Fix bad escape
    if (additionalInfo.picklistTextJustification) {
        additionalInfo.picklistTextJustification = additionalInfo.picklistTextJustification.replace("&#39;", "'");
    }

    console.log(additionalInfo);

    var tmpl = $.templates("#booking-justification-html");
    var html = tmpl.render(additionalInfo);

    var sa2 = Swal.fire({
        title: null,
        icon: null,
        html: html,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: function (dom) {
            //console.log(dom);
            $("#swal2-content form").on("submit", function (e) {
                e.preventDefault();
                $("#swal2-content form :input").each(function () {
                    var type = $(this).attr("type");
                    var name = $(this).attr("name");
                    var value = $(this).val();
                    if (type == "submit") {
                        return;
                    }

                    if ($('input[name="additionalInfo[' + name + ']"]', form).length) {
                        $('input[name="additionalInfo[' + name + ']"]', form).val(value);
                    } else {
                        console.log("Missing justification field:" + name);
                    }
                });
                Swal.close();
                onComplete();
            })
        },
        didDestroy: () => null,
        willClose: () => null,
        didClose: () => null,
    });

}
